// @ts-nocheck

import React, { Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import MainFooter from 'components/Core/Footer/MainFooterSection/MainFooter';
import InvoiceMoreDetails from 'components/Core/Invoice/MoreDetails/InvoiceMoreDetails';
import NonPayEnable from 'components/Core/NonPayEnabled/UnpayableCard/Growth/NonPayEnable';
import StandardPaymentHelpMsg from 'components/Core/NonPayEnabled/UnpayableCard/StandardPaymentHelpMsg/StandardPaymentHelpMsg';
import TrustSection from 'components/Core/TrustSection/TrustSection';
import PaymentRequestMoreDetails from 'components/PaymentReq/MoreDetails/PaymentRequestMoreDetails';
import Card from 'components/Shared/Card/Card';
import ConfirmationEmailMsg from 'components/Shared/ConfirmationEmailMsg/ConfirmationEmailMsg';
import FullPaymentMsg from 'components/Shared/FullPaymentMsg/FullPaymentMsg';
import MobileInvoiceHeader from 'components/Shared/MobileInvoiceHeader/MobileInvoiceHeader';
import Separator from 'components/Shared/Separator/Separator';
import { isPaymentRequest } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { isGrowthNpeFreeTextEmailEnabled } from 'store/ixp/selector';
import { saleSelectors } from 'store/sale/selectors';
import { colors, fontSize, breakpoints } from 'styles/cp';
import { CompanyInfo } from 'types/CompanyInfo';
import { Config } from 'types/Config';
import { IXP } from 'types/IXP';
import { COUNTRY } from 'types/constants';

export interface Props {
  companyInfo: CompanyInfo;
  insight: any;
  sale: any;
  config: Config['endpoints']['cdn'];
  showModal: (Component: React.FC, args: Object) => void;
  fetchPDFStatus: string;
  pdfUrl: string;
  balanceAmount: number;
  featureFlags: Record<string, any>;
  paymentDetailsMessage: string;
  allowOnlinePPAAMPayment?: boolean;
  ixp: IXP;
}

const UnpayableCard: React.FC<Props> = ({
  companyInfo = {},
  sale = {},
  config = { endpoints: { cdn: '' } },
  insight = {},
  fetchPDFStatus,
  pdfUrl,
  showModal,
  balanceAmount,
  featureFlags = {},
  ixp,
  paymentDetailsMessage,
  allowOnlinePPAAMPayment,
}: Props) => {
  const balanceAmountToDisplay = balanceAmount < 0 ? 0 : balanceAmount;
  const companyEmail = companyInfoSelectors.emailSelector(companyInfo);
  const companyPhone = companyInfoSelectors.phoneSelector(companyInfo);
  const companyWebAddr = companyInfoSelectors.webAddrSelector(companyInfo);
  const companyAddress = companyInfoSelectors.addressSelector(companyInfo);
  const companyName = companyInfoSelectors.nameSelector(companyInfo);
  const companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);
  const companyLocale = companyInfoSelectors.localeSelector(companyInfo);
  const number = saleSelectors.referenceNumberSelector(sale);
  const dueDate = saleSelectors.dueDateSelector(sale);
  const currency = saleSelectors.currencySelector(sale);
  const amount = saleSelectors.amountSelector(sale);
  const description = saleSelectors.descriptionSelector(sale);
  const transactionType = saleSelectors.typeSelector(sale);

  const txnDate = saleSelectors.txnDateSelector(sale);
  const token = insightSelectors.tokenSelector(insight);
  const domainId = insightSelectors.domainId(insight);
  const companyId = insightSelectors.companyIdSelector(insight);
  const isFullyPaid = insightSelectors.isFullyPaidSelector(insight);
  const isPayEnabled = insightSelectors.isPayEnabledSelector(insight);
  const isPartiallyPaid = insightSelectors.isPartiallyPaidSelector(insight);
  const isNonPayEnableInvoiceFlag = featureFlags['growth-non-pay-enable-invoice'];
  const shouldShowGrowthNpeFreeTextModal = isGrowthNpeFreeTextEmailEnabled({
    ixp,
    featureFlags,
  });

  const { endpoints: { cdn } = { cdn: '' } } = config;
  const isPaymentRequestTxn = isPaymentRequest(transactionType);
  let MoreDetails;

  const shouldShowNpeGrowthFeature = () => {
    const country = companyLocale.split('_')[1];
    return isNonPayEnableInvoiceFlag && country === COUNTRY.US;
  };

  if (isPaymentRequestTxn) {
    MoreDetails = (
      <PaymentRequestMoreDetails
        companyAddress={companyAddress}
        companyEmail={companyEmail}
        companyPhone={companyPhone}
        companyWebAddr={companyWebAddr}
        number={number}
        dueDate={dueDate}
        amount={amount}
        balanceAmount={balanceAmount}
        currency={currency}
        isPartiallyPaid={isPartiallyPaid}
        description={description}
        txnDate={txnDate}
        showModal={showModal}
      />
    );
  } else {
    MoreDetails = (
      <InvoiceMoreDetails
        featureFlags={featureFlags}
        showModal={showModal}
        companyAddress={companyAddress}
        companyEmail={companyEmail}
        companyPhone={companyPhone}
        companyWebAddr={companyWebAddr}
        number={number}
        dueDate={dueDate}
        amount={amount}
        balanceAmount={balanceAmount}
        currency={currency}
        isPartiallyPaid={isPartiallyPaid}
        pdfUrl={pdfUrl}
        token={token}
        fetchPDFStatus={fetchPDFStatus}
        lazyFetch={false}
      />
    );
  }
  return (
    <Fragment>
      <Card>
        <MobileInvoiceHeader
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          isUnPayable={true}
        />
        {MoreDetails}
        <div className="main-card-wrapper">
          <div className="payment-amount-wrapper">
            {isFullyPaid && <FullPaymentMsg />}

            <span className="payment-amount-label">
              <FormattedMessage id="INVOICE_SUMMARY_BALANCE_DUE" defaultMessage="balance due" />
            </span>
            <span className="payment-amount">
              <FormattedNumber
                value={balanceAmountToDisplay}
                style="currency"
                currency={currency}
              />
            </span>
          </div>

          {isFullyPaid ? (
            <ConfirmationEmailMsg />
          ) : shouldShowNpeGrowthFeature() ? (
            <NonPayEnable
              companyId={companyId}
              domainId={domainId}
              companyName={companyName}
              merchantEmail={companyEmail}
              showModal={showModal}
              isFreeTextModalEnabled={shouldShowGrowthNpeFreeTextModal}
              country={companyLocale}
            />
          ) : (
            <StandardPaymentHelpMsg
              companyName={companyName}
              isPayEnabled={isPayEnabled}
              allowOnlinePPAAMPayment={allowOnlinePPAAMPayment}
              paymentDetailsMessage={paymentDetailsMessage}
            />
          )}
          <div className="truste-w">
            <TrustSection cdn={cdn} />
          </div>
        </div>
        <Separator height={15} />
        {
          <div className="footer-section">
            <MainFooter />
          </div>
        }
      </Card>
      {/*language=SCSS*/}
      <style jsx>{`
        .main-card-wrapper {
          padding: 15px 60px 0;

          @media screen and (max-width: ${breakpoints.md}) {
            padding: 20px 20px 0;
          }
        }
        .ck-test-w {
          padding: 0 60px 15px;

          @media screen and (max-width: ${breakpoints.md}) {
            padding: 0 20px;
          }
        }
        .payment-amount-wrapper {
          text-align: center;
          margin-top: 4px;
        }

        .payment-amount-label {
          font-family: AvenirNextforINTUIT-Regular;
          font-size: ${fontSize.xxs};
          color: ${colors.whiteGray};
          text-transform: uppercase;
          display: block;
          padding-bottom: 4px;
        }

        .payment-amount {
          font-family: AvenirNextforINTUIT-Demi;
          font-size: ${fontSize.xl};
          line-height: 37px;
          color: ${colors.darkGray};
          text-transform: uppercase;
          display: block;
        }

        .truste-w {
          @media screen and (min-width: ${breakpoints.md}) {
            display: none;
          }

          margin: 15px 0;
        }
      `}</style>
    </Fragment>
  );
};

export default UnpayableCard;
